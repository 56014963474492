/*
We need to overide some CSS of the INGKA SKAPA component library
in order to align or design with Figma.

SKAPA: https://www.cte.ikeadt.com/fw/fw/skapa-coded-components/
Figma: https://www.figma.com/file/5dgLh4SoMAC2kKXNynaMpf/MVP-Wireframes-Desktop---Work-in-progress
 */

/* Remove bottom margin from Stacked modals */
.modal-wrapper.modal-wrapper--open {
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  z-index: 2000;
}

/* Fix modal button issue where link buttons dont get margins */
.modal-footer--compact > *:first-child {
  margin-bottom: 0;
  margin-left: 1rem;
}

button.tabs__tab.tabs__tab--active::after {
  z-index: 2;
}

#search-store::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.accordion__content#SEC_appointmentDate {
  overflow: visible;
}

.input-field__wrapper {
  background-color: #fff;
}

.rbc-calendar {
  font-family: "Noto IKEA Latin", "Noto IKEA", "Noto Sans", "Roboto",
    "Open Sans", sans-serif;
  z-index: 0;
}

span.iconAppCompleted path {
  fill: #8c8c8c;
}

span.iconAppColor path {
  fill: #111;
}

input#confirmationMessage {
  z-index: -1;
}

@import "./skapaOverrides.css";

body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Noto IKEA Latin", "Noto IKEA", "Noto Sans", "Roboto",
    "Open Sans", sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
